
import { defineComponent } from 'vue';

import AppTooltip from '@/components/stateless/AppTooltip.vue';
import AppButton from '@/components/stateless/AppButton.vue';
import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

export default defineComponent({
  name: 'Tooltip',

  components: { AppTooltip, AppButton, AppTruncatedTooltip },

});
