<template>
  <div class="text-primary text-3xl text-center py-7 border-b border-grey-fp-30">Tooltip</div>
  <div class="text-dark-cl-20 mt-20">
    <div class="flex flex-col h-full">
      <!-- T O O L T I P -->
      <div>
        <div class="text-1xl mb-10">Placements:</div>
        <div class="flex flex-wrap -m-10">
          <!-- R I G H T  -  S T A R T -->
          <div class="m-10">
            <AppTooltip placement="right-start" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">right-start</AppButton>
            </AppTooltip>
          </div>

          <!-- R I G H T -->
          <div class="m-10">
            <AppTooltip placement="right" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">right</AppButton>
            </AppTooltip>
          </div>

          <!-- R I G H T  -  E N D -->
          <div class="m-10">
            <AppTooltip placement="right-end" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">right-end</AppButton>
            </AppTooltip>
          </div>

          <!-- T O P  -  S T A R T -->
          <div class="m-10">
            <AppTooltip placement="top-start" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">top-start</AppButton>
            </AppTooltip>
          </div>

          <!-- T O P -->
          <div class="m-10">
            <AppTooltip placement="top" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">top</AppButton>
            </AppTooltip>
          </div>

          <!-- T O P  -  E N D -->
          <div class="m-10">
            <AppTooltip placement="top-end" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">top-end</AppButton>
            </AppTooltip>
          </div>

          <!-- L E F T  -  S T A R T -->
          <div class="m-10">
            <AppTooltip placement="left-start" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">left-start</AppButton>
            </AppTooltip>
          </div>

          <!-- L E F T -->
          <div class="m-10">
            <AppTooltip placement="left" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">left</AppButton>
            </AppTooltip>
          </div>

          <!-- L E F T  -  E N D -->
          <div class="m-10">
            <AppTooltip placement="left-end" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">left-end</AppButton>
            </AppTooltip>
          </div>

          <!-- B O T T O M  -  S T A R T -->
          <div class="m-10">
            <AppTooltip placement="bottom-start" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">bottom-start</AppButton>
            </AppTooltip>
          </div>

          <!-- B O T T O M -->
          <div class="m-10">
            <AppTooltip placement="bottom" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">bottom</AppButton>
            </AppTooltip>
          </div>

          <!-- B O T T O M  -  E N D -->
          <div class="m-10">
            <AppTooltip placement="bottom-end" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.">
              <AppButton type="primary" size="small" plain class="px-10">bottom-end</AppButton>
            </AppTooltip>
          </div>
        </div>
      </div>

      <div class="mt-20">
        <div class="text-1xl mb-10">Custom tooltip:</div>
        <AppTooltip placement="right" hideArrow>
          <AppButton type="primary" size="small" plain class="px-10">Some custom activator</AppButton>
          <template #content>
            <div class="app-tooltip__custom-content">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum, hic.
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum, hic.
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum, hic.
            </div>
          </template>
        </AppTooltip>
      </div>

      <div class="mt-20">
        <div class="text-1xl mb-10">Trigger:</div>
        <AppTooltip
          btn-title="Click" trigger="click" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit"
        />
        <AppTooltip
          btn-title="Hover" content="Lorem ipsum dolor sit amet, consectetur adipisicing elit" class="ml-20"
        />
      </div>
    </div>

    <!-- T R U N C A T E D   T O O L T I P -->
    <div class="mt-10">
      <div class="text-1xl">TruncatedTooltip:</div>
      <div class="text-sm mb-20">
        (Automatic truncation when text is long and displaying a tooltip with full text, on mouse hover)
      </div>

      <div class="bg-bering-wave text-white rounded-5 p-10" style="width: 200px">
        <AppTruncatedTooltip class="text-dark-cl-20" content="Long Text Long Text Long Text" /> 

        <div class="h-1 w-full bg-black my-10" />

        <AppTruncatedTooltip class="text-dark-cl-20" content="Short text" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppTooltip from '@/components/stateless/AppTooltip.vue';
import AppButton from '@/components/stateless/AppButton.vue';
import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

export default defineComponent({
  name: 'Tooltip',

  components: { AppTooltip, AppButton, AppTruncatedTooltip },

});
</script>

<style lang="scss" scoped>
.app-tooltip__custom-content {
  @apply p-10 bg-white text-dark-cl-20 rounded-5;
  max-width: 500px;
}
</style>
